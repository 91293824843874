import React from 'react';
import Layout from '../layouts/de';
import timboAfrica from '../assets/images/timbo-africa_2.jpg';
import longTermGoals from '../assets/images/long-term-goals_2.jpg';
import iconMarketingSalesStrategy from '../assets/images/icons/icon-marketing-sales-strategy.svg';
import iconProfability from '../assets/images/icons/icon-profability.svg';
import iconIncreaseParcel from '../assets/images/icons/icon-increase-parcel.svg';
import iconFinancialSupport from '../assets/images/icons/icon-financial-support.svg';
import timboLogoWhite from '../assets/images/timbo-logo-white.png';
import Scroll from './../components/Scroll';

export const MARKETING_SALES = 1;
export const PROFITABILITY = 2;
export const INCREASE_PARCEL = 3;
export const FINANCIAL_SUPPORT = 4;

class corporateResponsibilityPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mobileStrategies: {
                marketingSales: false,
                profability: false,
                increaseParcel: false,
                financialSupport: false,
            }        
        }
    }

    handleExpandingFullStrategy(section) {
        switch (section) {
            case MARKETING_SALES:
                this.setState({ mobileStrategies: {
                    marketingSales: true,
                    profability: this.state.mobileStrategies.profability,
                    increaseParcel: this.state.mobileStrategies.increaseParcel,
                    financialSupport: this.state.mobileStrategies.financialSupport
                }});
                // this.mobileStrategies.marketingSales = true;
                break;
            case PROFITABILITY:
                this.setState({ mobileStrategies: {
                    profability: true,
                    marketingSales: this.state.mobileStrategies.marketingSales,
                    increaseParcel: this.state.mobileStrategies.increaseParcel,
                    financialSupport: this.state.mobileStrategies.financialSupport
                }});
                // this.mobileStrategies.profability = true;
                break;
            case INCREASE_PARCEL:
                this.setState({ mobileStrategies: {
                    increaseParcel: true,
                    profability: this.state.mobileStrategies.profability,
                    marketingSales: this.state.mobileStrategies.marketingSales,
                    financialSupport: this.state.mobileStrategies.financialSupport
                }});
                // this.mobileStrategies.increaseParcel = true;
                break;
            case FINANCIAL_SUPPORT:
                this.setState({ mobileStrategies: {
                    financialSupport: true,
                    profability: this.state.mobileStrategies.profability,
                    increaseParcel: this.state.mobileStrategies.increaseParcel,
                    marketingSales: this.state.mobileStrategies.marketingSales
                }});
                // this.mobileStrategies.financialSupport = true;
                break;
            default:
                break;
        }
    }

    handleCollapsingStrategy(section) {
        switch (section) {
            case MARKETING_SALES:
                this.setState({ mobileStrategies: {
                    marketingSales: false,
                    profability: this.state.mobileStrategies.profability,
                    increaseParcel: this.state.mobileStrategies.increaseParcel,
                    financialSupport: this.state.mobileStrategies.financialSupport
                }});
                // this.mobileStrategies.marketingSales = true;
                break;
            case PROFITABILITY:
                this.setState({ mobileStrategies: {
                    profability: false,
                    marketingSales: this.state.mobileStrategies.marketingSales,
                    increaseParcel: this.state.mobileStrategies.increaseParcel,
                    financialSupport: this.state.mobileStrategies.financialSupport
                }});
                // this.mobileStrategies.profability = true;
                break;
            case INCREASE_PARCEL:
                this.setState({ mobileStrategies: {
                    increaseParcel: false,
                    profability: this.state.mobileStrategies.profability,
                    marketingSales: this.state.mobileStrategies.marketingSales,
                    financialSupport: this.state.mobileStrategies.financialSupport
                }});
                // this.mobileStrategies.increaseParcel = true;
                break;
            case FINANCIAL_SUPPORT:
                this.setState({ mobileStrategies: {
                    financialSupport: false,
                    profability: this.state.mobileStrategies.profability,
                    increaseParcel: this.state.mobileStrategies.increaseParcel,
                    marketingSales: this.state.mobileStrategies.marketingSales
                }});
                // this.mobileStrategies.financialSupport = true;
                break;
            default:
                break;
        }
    }

    render() {
                     
        return (
            <Layout>
                <header id="header" className="masthead corporate-responsibility-photo">
                    <div className="container d-flex h-100 align-items-center page-wrapper">
                        <div className="center-block text-center col-sm-12">
                            <div className="timbo-logo-wrapper">
                                <img src={timboLogoWhite} alt="Timbo Africa Foundation" />
                            </div>
                            <h1 className="mx-auto my-0 homepage-main-text corporate-responsibility-main-text">Unser Ansatz in der Praxis</h1>
                        </div>
                    </div>
                </header>

                <section className="container content-wrapper corporate-responsibility-wrapper">
                    <div className="corporate-responsibility-container first-corporate-responsibility-container">
                        <div className="corporate-responsibility-section">
                            <h2 className="corporate-responsibility-title">Timbo Africa Stiftung</h2>
                            <div className="corporate-responsibility-text">
                                <p>
                                Die Timbo Stiftung setzt sich für den Schutz der Wildtiere in Afrika ein, insbesondere durch die Schaffung von Bewegungskorridoren zwischen bestehenden Naturgebieten. Zusätzlich wird die lokale Jugend durch Forschung, Ausbildung und Schulungen eingebunden und eine Perspektive für Karrieren im Naturschutz geschaffen.
                                </p>
                                <br/>
                                <p>
                                    Die Timbo Stiftung wurde 2009 von ACC-Mitgründer Albert Hartog ins Leben gerufen.
                                </p>
                                <br/>
                                <p>
                                Unsere Vision (Timbo 2050): Schaffung eines der weltweit größten Wildtierkorridore zwischen Botswana und Mosambik für zukünftige Generationen.
                                </p>
                                <br/>
                                <p>
                                Das Koro River Camp wurde im Reservat der Timbo Stiftung als nachhaltige Einkommensquelle zur Finanzierung der Stiftungsaktivitäten (u.a. Wilderungsbekämpfung und Naturschutz) eingerichtet.
                                </p>
                            </div>
                        </div>
                        <div className="corporate-responsibility-section">
                            <div className="corporate-responsibility-first-image">
                                <img src={timboAfrica} alt="Timbo Africa Foundation"/>        
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                    <div className="clearfix"></div>

                    <div className="corporate-responsibility-container second-responsibility-container">
                        <div className="corporate-responsibility-section corporate-responsibility-custom-section">
                            <div className="corporate-responsibility-text">
                                <p>
                                Die Stiftung ist vor allem in Botswana und Südafrika tätig. Weiterhin sammelt sie Mittel zur Unterstützung der Krebsforschung in den Niederlanden.
                                </p>
                            </div>
                            <h2 className="corporate-responsibility-title">Langfristige Ziele</h2>
                            <div className="corporate-responsibility-text">
                                <p>
                                Führende Rolle bei der Realisierung von Bewegungskorridoren im Kruger-Nationalpark und in Mosambik
                                </p>
                                <br/>
                                <p>
                                    Ausbildung der lokalen Bevölkerung für Tätigkeiten in den Bereichen Naturschutz und Tourismus
                                </p>
                                <br/>
                                <p>
                                    Wiederansiedlung gefährdeter Arten
                                </p>
                                <br/>
                                <p>
                                Unterstützung der Forschung an Land und Arten (z. B. Wildzählung, Ermittlung maximaler Populationsgrößen)
                                </p>
                            </div>
                        </div>
                        <div className="corporate-responsibility-section">
                            <div className="corporate-responsibility-second-image">
                                <img src={longTermGoals} alt="Long term goals"/>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>

                    <div className="corporate-responsibility-container third-corporate-responsibility-container">
                        <div className="corporate-responsibility-information-container corporate-responsibility-information-container-mobile-hide">
                            <div className="corporate-responsibility-information-first">
                                &nbsp;    
                            </div>
                            <div className="corporate-responsibility-information-first corporate-responsibility-information-custom-title">
                                <div className="corporate-responsibility-information-title">
                                Ziel
                                </div>
                            </div>
                            <div className="corporate-responsibility-information-first corporate-responsibility-information-custom-title">
                                <div className="corporate-responsibility-information-title">
                                    Unser Beitrag
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>

                        <div className="corporate-responsibility-information-container">
                            <div className="corporate-responsibility-information-first">
                                <img src={iconMarketingSalesStrategy} alt="Marketing and sales strategy" />
                                <div className="corporate-responsibility-icon-title">
                                    Marketing - und <br />
                                    Vertriebsstrategie
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="clearfix desktop-hidden"></div>
                            <div onClick={() => this.handleExpandingFullStrategy(MARKETING_SALES)} className={ this.state.mobileStrategies.marketingSales ? 'full-strategy-button hide-full-button' : 'full-strategy-button' }>
                                <span>Erfahren Sie mehr über unsere Strategie</span>
                            </div>
                            <div className={ this.state.mobileStrategies.marketingSales ? 'corporate-responsibility-info-wrapper display-mobile' : 'corporate-responsibility-info-wrapper' }>
                                <div className="corporate-responsibility-information-second">
                                    <h4 className="corporate-responsibility-information-mobile-title">Ziel</h4>
                                    Etablierung der Lodge als verlässliche Einkommensquelle zur langfristigen Finanzierung des Naturschutzes
                                </div>
                                <div className="corporate-responsibility-information-third">
                                    <h4 className="corporate-responsibility-information-mobile-title">Unser Beitrag</h4>
                                    Aufbau einer Marketing - und Vertriebsstrategie für das Koro River Camp zur Maximierung der Auslastung
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div onClick={() => this.handleCollapsingStrategy(MARKETING_SALES)} className={ this.state.mobileStrategies.marketingSales ? 'show-less-button' : 'show-less-button hide-full-button' }>
                                <span>Weniger anzeigen</span>
                            </div>
                        </div>

                        <div className="corporate-responsibility-information-container">
                            <div className="corporate-responsibility-information-first">
                                <img src={iconProfability} alt="Profability" />
                                <div className="corporate-responsibility-icon-title">Rentabilität</div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="clearfix desktop-hidden"></div>
                            <div onClick={() => this.handleExpandingFullStrategy(PROFITABILITY)} className={ this.state.mobileStrategies.profability ? 'full-strategy-button hide-full-button' : 'full-strategy-button' }>
                                <span>Erfahren Sie mehr über unsere Strategie</span>
                            </div>
                            <div className={ this.state.mobileStrategies.profability ? 'corporate-responsibility-info-wrapper display-mobile' : 'corporate-responsibility-info-wrapper' }>
                                <div className="corporate-responsibility-information-second">
                                    <h4 className="corporate-responsibility-information-mobile-title">Ziel</h4>
                                    Etablierung der Lodge als verlässliche Einkommensquelle zur langfristigen Finanzierung des Naturschutzes
                                </div>
                                <div className="corporate-responsibility-information-third">
                                    <h4 className="corporate-responsibility-information-mobile-title">Unser Beitrag</h4>
                                    Ausrichtung des Koro River Camp nach dem Muster unserer Portfoliounternehmen für langfristiges Wachstum und nachhaltige Erträge
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div onClick={() => this.handleCollapsingStrategy(PROFITABILITY)} className={ this.state.mobileStrategies.profability ? 'show-less-button' : 'show-less-button hide-full-button' }>
                                <span>Weniger anzeigen</span>
                            </div>
                        </div>

                        <div className="corporate-responsibility-information-container">
                            <div className="corporate-responsibility-information-first">
                                <img src={iconIncreaseParcel} alt="Increase parcel" />
                                <div className="corporate-responsibility-icon-title">Vergrößerung des Reservats</div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="clearfix desktop-hidden"></div>
                            <div onClick={() => this.handleExpandingFullStrategy(INCREASE_PARCEL)} className={ this.state.mobileStrategies.increaseParcel ? 'full-strategy-button hide-full-button' : 'full-strategy-button' }>
                                <span>Erfahren Sie mehr über unsere Strategie</span>
                            </div>
                            <div className={ this.state.mobileStrategies.increaseParcel ? 'corporate-responsibility-info-wrapper display-mobile' : 'corporate-responsibility-info-wrapper' }>
                                <div className="corporate-responsibility-information-second">
                                    <h4 className="corporate-responsibility-information-mobile-title">Ziel</h4>
                                    Schaffung einer Verbindung zwischen dem Timbo Flussreservat und dem Northern Tuli Wildreservat
                                </div>
                                <div className="corporate-responsibility-information-third">
                                    <h4 className="corporate-responsibility-information-mobile-title">Unser Beitrag</h4>
                                    Fachliche Unterstützung bei dem Erwerb von Grundstücken zur Erweiterung des Korridors
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div onClick={() => this.handleCollapsingStrategy(INCREASE_PARCEL)} className={ this.state.mobileStrategies.increaseParcel ? 'show-less-button' : 'show-less-button hide-full-button' }>
                                <span>Weniger anzeigen</span>
                            </div>
                        </div>

                        <div className="corporate-responsibility-information-container last-corporate-responsibility-information-container">
                            <div className="corporate-responsibility-information-first">
                                <img src={iconFinancialSupport} alt="Financial support" />
                                <div className="corporate-responsibility-icon-title">Finanzielle Unterstützung</div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="clearfix desktop-hidden"></div>
                            <div onClick={() => this.handleExpandingFullStrategy(FINANCIAL_SUPPORT)} className={ this.state.mobileStrategies.financialSupport ? 'full-strategy-button hide-full-button' : 'full-strategy-button' }>
                                <span>Erfahren Sie mehr über unsere Strategie</span>
                            </div>
                            <div className={ this.state.mobileStrategies.financialSupport ? 'corporate-responsibility-info-wrapper display-mobile' : 'corporate-responsibility-info-wrapper' }>
                                <div className="corporate-responsibility-information-second">
                                    <h4 className="corporate-responsibility-information-mobile-title">Ziel</h4>
                                    Fachliche Unterstützung bei langfristiger Investitions- und Kapitalbedarfsplanung für die Expansion
                                </div>
                                <div className="corporate-responsibility-information-third">
                                    <h4 className="corporate-responsibility-information-mobile-title">Unser BeitragEin</h4>
                                    Ein Prozentsatz der ACC Gewinne werden an Timbo gespendet
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div onClick={() => this.handleCollapsingStrategy(FINANCIAL_SUPPORT)} className={ this.state.mobileStrategies.financialSupport ? 'show-less-button' : 'show-less-button hide-full-button' }>
                                <span>Weniger anzeigen</span>
                            </div>
                        </div>

                    </div>
                    <div className="clearfix"></div>
                    
                </section>
                <Scroll type="id" element="header">
                    <div className="csr-back back-to-top-wrapper">
                        <a href="?scroll-to=header">
                            <i className="fas fa-angle-double-up"></i>
                            <br />
                            <span className="back-to-top">Zurück zu Start</span>
                        </a>
                    </div>
                </Scroll>
            </Layout>
        )    
    }
}

export default corporateResponsibilityPage;